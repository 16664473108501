import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Education</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <ul>
            <li>2017-2018, Master Degree in Sport Management, <a href="https://international.ucam.edu/" target="_blank">Catholic University of Murcia (UCAM), Spain</a></li>
            <li>2012-2016, Bachelor of Science in Hotel Management, <a href="http://www.eul.edu.tr/en/" target="_blank">European University of Lefke</a>, Cyprus</li>
          </ul>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Playing Experience</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            I have played basketball since I was 14 years old. I have played basketball as a Center player
            and for many teams across the world. I have played professionally in Cyprus, Turkey, Qatar, and in Iran since 2005.
          </p>
          <ul>
            <li>2015 - 2016, Player (C), European University of Lefke Sports Team, Lefke</li>
            <li>2013 - 2014, Player (C), Ozyegin University Basketball Team, Istanbul, Turkey​</li>
            <li>2012 - 2013, Player (C), Bandar-e-Kong Sports Club, Bandar-e-Kong, Iran​</li>
            <li>2010 - 2011, Player (C), Eslamshahr Municipal Sports Team, Eslamshahr, Iran</li>
            <li>2008 - 2009, Player (C), Qatar Sports Club, Doha, Qatar​</li>
            <li>2006 - 2008, Player (C), Mahram Sports Club, Tehran, Iran​</li>
            <li>2005 - 2006, Player (C), Pegah Shiraz Sports Club, Shiraz, Iran​</li>
          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Coaching Experience</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
          I have been teaching basketball as an assistant coach since 2016. I love teaching and I love basketball
          so this makes me super passionate about being a basketball coach. I am open to coaching all levels from 
          3 year-olds to college students. I have a coaching license (Grade 3) from the Iranian Basketball Federation (License No. C9602080303, Issued 22/05/2017).
          </p>
          <li>June - July 2019, Assistant Coach, Stryx Sports, NBA Basketball School Camp, Dubai, UAE</li>
          <li>2018-2019, Assistant Coach, Club Huercalense Atlético, Huercal Overa, Spain</li>
          <li>2017-2018, Assistant Coach, AD Infante Sports Club, Murcia, Spain</li>
          <li>2016-2017, Assistant Coach, Hiro Javan Basketball Club, Tehran, Iran</li>

          <p>
          Watch some of sample coaching
          </p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/iiVvzX-FOvI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/mohammad-hadavand-73614a115"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/mohammad.hadavand.1" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <br />
            <br />
            <li>
              <a
                href="mailto:mohdvnd86@gmail.com?Subject=Hello%20Mohammad"
                className="icon fa-envelope"
              >
                <span className="label">Email</span>
              </a> &nbsp;&nbsp;  mohdvnd86*at*gmail*dot*com*
            </li>
            <br />
            <br />
            <li>
              <a
                className="icon fa-phone"
              >
                <span className="label">Phone</span>
              </a>  &nbsp;&nbsp; US: +1 (347) 791-8122
            </li>
            <br />
            <br />
            <li>
              <a
                className="icon fa-phone"
              >
                <span className="label">Phone</span>
              </a>  &nbsp;&nbsp; Iran/Whatsapp: +98 (912) 226-3129
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
