import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-globe"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Mohammad Hadavand</h1>
                <p>Welcome to my personal website. I love playing and teaching basketball.<br />
                  I am a basketball player and an assistant coach.<br />
                  I have played professionally in Spain 🇪🇸, Turkey 🇹🇷, Cyprus 🇨🇾, Qatar 🇶🇦, and Iran 🇮🇷,<br />
                  and have worked as an assistant coach in Spain 🇪🇸, UAE/Dubai 🇦🇪, and Iran 🇮🇷.
                 </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Education</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Playing Experience</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Coaching Experience</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
